<template>
  <div>
    <ComHeader menuKey="authpro"></ComHeader>
    <!-- banner -->
    <div class="common-banner">
      <div class="container ui acenter">
        <img
          class="banner-icon animate__animated animate__backInLeft"
          src="@/assets/images/authpro/authpro-banner-icon.png"
          alt=""
        />
        <div class="f1 animate__animated animate__backInDown">
          <p class="banner-title">实我风险核验系统</p>
          <p class="banner-content">
            实我风险核验产品，应用“互联网+”可信身份认证、物联网、云数据等新技术手段，依托手持核验机、Q版、立式人证机、人证闸机等设备，提供“预约登记、人像对应、主动预防、报表汇总”等全场景的核验解决方案，真正做到人员、证件、照片三者统一，为机关、企事业单位构筑安全防控圈。
          </p>
        </div>
      </div>
    </div>
    <!-- 产品 -->
    <div class="container">
      <div class="q-wrap">
        <!-- <div class="q-introduce">
          <img class="q-intr-img" src="@/assets/images/authpro/authpro-sysimg.png" alt="">
          <div class="q-txt">
            <div class="title">实我风险核验系统</div>
            <p>实我风险核验产品，应用“互联网+”可信身份认证、人工智能、大数据、物联网、云数据等新技术手段，依托手持核验机、Q版、立式人证机、人证闸机等设备，提供“预约登记、进出留痕、人像对应、主动预防、过程预警、报表汇总”等全场景的核验解决方案，真正做到人员、证件、照片三者统一，通过人防和技防相结合，有效避免登记成本高、效率低、准确性低、身份识别难、安全风险高等问题，为机关、企事业单位构筑安全防控圈</p>
          </div>
        </div> -->
        <!-- “实我”业务流程 -->
        <div class="com-title">
          <div class="title">“实我”业务流程</div>
          <div>procedure</div>
        </div>
        <div class="business-wrap">
          <img
            class="business-img"
            src="@/assets/images/authpro/authpro-process.png"
            alt=""
          />
        </div>
        <!-- 系统优势 -->
        <div class="com-title">
          <div class="title">系统优势</div>
          <div>advantages</div>
        </div>
        <div class="system-wrap">
          <div
            class="system-item hvr-shadow-radial hvr-curl-top-left"
            v-for="(item, index) in systemList"
            :key="index"
          >
            <div class="title">{{ index + 1 }}、{{ item.title }}</div>
            <div>{{ item.tip }}</div>
          </div>
        </div>
        <!-- 硬件部署 -->
        <div class="com-title">
          <div class="title">硬件部署</div>
          <div>hardware deployment</div>
        </div>
        <div class="hardware-wrap">
          <div
            class="hardware-item hvr-glow"
            v-for="(item, index) in hardwareList"
            :key="index"
          >
            <img class="hardware-img" :src="item.imgUrl" alt="" />
            <div class="f1">
              <div class="title">{{ item.title }}</div>
              <div>{{ item.tip }}</div>
            </div>
          </div>
        </div>
        <!-- 软件配套 -->
        <div class="com-title">
          <div class="title">软件配套</div>
          <div>Software matching</div>
        </div>
        <div class="applet-wrap">
          <div class="applet-item">
            <img
              class="applet-img1"
              src="@/assets/images/authpro/software1.png"
              alt=""
            />
            <div class="f1">
              <div class="title">B端“内保通”小程序</div>
              <div class="applet-content">
                直观便捷的对来访人员进行相应审核以及通行数据的统计和查看
              </div>
            </div>
          </div>
          <div class="applet-item">
            <img
              class="applet-img2"
              src="@/assets/images/authpro/software2.png"
              alt=""
            />
            <div class="f1">
              <div class="title">C端“实我”小程序</div>
              <div class="applet-content">
                具备唯一标识的身份认证信息，方便用户直接进行现场登记及来访预约
              </div>
            </div>
          </div>
          <div class="applet-item">
            <img
              class="applet-img3"
              src="@/assets/images/authpro/software3.png"
              alt=""
            />
            <div class="f1 applet-more">
              <div class="title">“实我”系统后台（PC端）</div>
              <div class="applet-content">
                大数据后台管理，角色划分保障安全，信源追溯，预案、圈层、布控、工作考核、指令、盘查一步到位，可以匹配不同应用场景需求，同时包含场所码，晚上人员进出管理
              </div>
            </div>
          </div>
        </div>
        <!-- 工作流程 -->
        <div class="com-title">
          <div class="title">工作流程</div>
          <div>Workflow</div>
        </div>
        <div class="work-wrap">
          <div class="work-item" v-for="(item, index) in workList" :key="item">
            <img class="work-img hvr-bounce-in" :src="item.imgUrl" alt="" />
            <div class="title">{{ item.title }}</div>
            <div>{{ item.tip }}</div>
            <div v-if="index < 5" class="num">
              <span class="num-txt">0{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: { ComHeader, ComFooter },

  setup() {
    const systemList = ref([
      { title: "关注人员预警", tip: "覆盖全国，数据鲜活" },
      { title: "警企联动", tip: "预警信息发送至辖区民警手机" },
      { title: "可信无证核验", tip: "安全可信、给予免费额度" },
      { title: "准确定位失效信息", tip: "快速准确、对接挂失库" },
      { title: "双网双平台", tip: "基层公安机关、内网后台服务" },
      { title: "数据安全", tip: "互联网不留存敏感数据" },
    ]);

    const hardwareList = ref([
      {
        title: "Q版核验机",
        imgUrl: require("@/assets/images/authpro/authpro-hardware1.png"),
        tip: "核验高效，临时卡点，展会活动，社区园区",
      },
      {
        title: "立式人证核验机",
        imgUrl: require("@/assets/images/authpro/authpro-hardware2.png"),
        tip: "脱网核查，边远地区，内保单位",
      },
      {
        title: "人证闸机",
        imgUrl: require("@/assets/images/authpro/authpro-hardware3.png"),
        tip: "快速通行，旅游景区，大型场馆",
      },
      {
        title: "手持核验设备",
        imgUrl: require("@/assets/images/authpro/authpro-hardware4.png"),
        tip: "高性价比，实时测温，移动核查，全景覆盖",
      },
    ]);

    const workList = ref([
      {
        title: "系统生成场所码 ",
        imgUrl: require("@/assets/images/authpro/process1.png"),
        tip: "管理员通过实我风险核验系统，生成适用于该机构场景下的场所码",
      },
      {
        title: "场所码",
        imgUrl: require("@/assets/images/authpro/process2.png"),
        tip: "打印出的场所码，可张贴于人员进出的出入口及门卫室等位置，用户可直接通过微信或实我小程序的扫码入口扫描该二维码进行现场登记",
      },
      {
        title: "扫码登记",
        imgUrl: require("@/assets/images/authpro/process3.png"),
        tip: "用户可通过微信或实我小程序，扫描相应的场所码，进行实我认证(首次)后，填写相应登记信息。 ",
      },
      {
        title: "来访登记",
        imgUrl: require("@/assets/images/authpro/process4.png"),
        tip: "填写相应人员信息，以及来访时间，来访事由，成功提交信息，进行来访信息记录备案。 ",
      },
      {
        title: "登记成功",
        imgUrl: require("@/assets/images/authpro/process5.png"),
        tip: "用户登记成功后，可凭借相应的个人二维码或身份证通过人证核验设备，确认无误后通行，同时人员信息会与黑名单进行实时比对 ",
      },
    ]);

    return {
      systemList,
      hardwareList,
      workList,
    };
  },
});
</script>

<style scoped lang="less">
.common-banner {
  background: url("../../assets/images/common-banner-bg.png") no-repeat;
  background-size: 100% 100%;
  .banner-icon {
    width: 246px;
    height: 217px;
    margin-right: 50px;
  }
}
.q-wrap {
  color: #142d70;
  .q-introduce {
    background-color: #f9f9f9;
    display: flex;
    margin-top: 150px;
    .q-txt {
      padding: 40px;
      flex: 1;
      .title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 600;
      }
    }
    .q-intr-img {
      width: 492px;
      height: 290px;
    }
  }
  .com-title {
    text-align: center;
    margin: 120px 0 65px;
    .title {
      font-weight: 600;
      font-size: 26px;
    }
  }
  .business-wrap {
    .business-img {
      width: 100%;
    }
  }
  .system-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .system-item {
      width: 350px;
      height: 420px;
      background-color: #110963;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 60px;
      font-size: 18px;
      &:nth-child(1) {
        background: url("../../assets/images/authpro/authpro-advantage1.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(2) {
        background: url("../../assets/images/authpro/authpro-advantage2.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(3) {
        background: url("../../assets/images/authpro/authpro-advantage3.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(4) {
        background: url("../../assets/images/authpro/authpro-advantage4.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(5) {
        background: url("../../assets/images/authpro/authpro-advantage5.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(6) {
        background: url("../../assets/images/authpro/authpro-advantage6.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .title {
        font-size: 23px;
        color: #00ffe6;
        margin-bottom: 30px;
        font-weight: 600;
      }
    }
  }
  .hardware-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .hardware-item {
      width: 49%;
      border-radius: 9px;
      border: 1px solid #979797;
      display: flex;
      align-items: center;
      padding: 20px;
      margin-bottom: 25px;
      .hardware-img {
        width: 200px;
        height: 200px;
        margin-right: 20px;
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }
  .applet-wrap {
    display: flex;
    justify-content: space-between;
    .applet-item {
      width: 28%;
      display: flex;
      align-items: center;
      &:last-child {
        width: 40%;
      }
      .applet-more {
        position: relative;
        top: 25px;
      }
      .applet-img1 {
        width: 100px;
        height: 185px;
        margin-right: 20px;
      }
      .applet-img2 {
        width: 100px;
        height: 185px;
        margin-right: 20px;
      }
      .applet-img3 {
        width: 188px;
        height: 182px;
        margin-right: 20px;
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
      }
    }
  }
  .work-wrap {
    display: flex;
    margin-bottom: 120px;
    .work-item {
      flex: 1;
      padding: 20px;
      position: relative;
      .work-img {
        width: 120px;
        display: block;
        margin: 0 auto 20px;
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }
      .num {
        position: absolute;
        right: -60px;
        top: 40px;
        width: 0;
        height: 0;
        border: 30px solid transparent;
        border-top: 50px solid #00ccef;
        transform: rotate(-90deg);
        .num-txt {
          display: block;
          transform: rotate(90deg);
          color: #fff;
          position: relative;
          top: -62px;
          font-weight: 600;
          font-size: 22px;
        }
      }
      &:nth-last-child(1) {
        .num {
          display: none;
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
.mobile {
  .common-banner {
    background: url("../../assets/images/common-banner-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 80px 20px 0;
    display: flex;
    align-items: center;
  }
  .q-wrap {
    padding:10px;
    .com-title {
      margin: 20px 0 10px;
    }
    .system-wrap {
      justify-content: center;
      align-items: center;
      .system-item {
        width: 46%;
        height: 200px;
        margin: 0 5px 10px 5px;
        font-size: 13px;
        .title {
          font-size: 16px;
        }
      }
    }
    .hardware-wrap {
      justify-content: center;
      .hardware-item {
        width: 96%;
        .hardware-img {
          width: 100px;
          height: 100px;
        }
      }
    }
    .applet-wrap {
      justify-content: center;
      flex-wrap: wrap;
      .applet-item {
        width: 96%;
        margin-bottom: 20px;
        .title {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .applet-content {
          font-size: 12px;
        }
        .applet-img1 {
          width: 84px;
          height: 150px;
        }
        .applet-img2 {
          width: 84px;
          height: 150px;
        }
        .applet-img3 {
          width: 90px;
          height: 88px;
        }
      }
    }
    .work-wrap {
      display: block;
      margin-bottom: 20px;
      .work-item {
        width: 96%;
        .num {
          right: 0;
          left: 30px;
        }
        &:nth-last-child(1) {
          .num {
            display: block;
          }
        }
      }
    }
  }
}
</style>