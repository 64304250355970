<template>
  <div class="footer">
    <!-- 描述 -->
    <div class="describe">
      <ul class="describe-list container">
        <li class="describe-item">
          <img src="@/assets/images/footer-logo1.png" alt="">
          <div class="des-title">权威数据、覆盖全面</div>
          <div>直连权威数据源，数据真实可靠实时更新</div>
        </li>
        <li class="describe-item">
          <img src="@/assets/images/footer-logo2.png" alt="">
          <div class="des-title">智能路由、准确率高</div>
          <div>智能路由规则、动态分配通道，验证准确率高于同行业平台</div>
        </li>
        <li class="describe-item">
          <img src="@/assets/images/footer-logo3.png" alt="">
          <div class="des-title">便捷接入、秒级响应</div>
          <div>系统分布式部署，专线联通各数据源</div>
        </li>
        <li class="describe-item">
          <img src="@/assets/images/footer-logo4.png" alt="">
          <div class="des-title">信息安全、有效风控</div>
          <div>金融级安全要求，用户数据全脱敏，确保敏感信息无泄漏</div>
        </li>
      </ul>
    </div>
    <div class="container">
      <!-- footer info -->
      <div class="ui footer-main pt-40">
        <div class="f1 footer-info">
          <img class="footer-logo" src="@/assets/images/footer-logo.png" alt="">
          <div class="footer-text">
            <div class="address">
              <img class="logo" src="@/assets/images/address.png" />
              <div>地址：四川省成都市高新区天府新谷10号楼2301</div>
            </div>
            <div class="phone">
              <img class="logo" src="@/assets/images/phone.png" />
              <div>电话：19182082426</div>
            </div>
            <div class="message">
              <img class="logo" src="@/assets/images/message.png" />
              <div>邮箱：marketing@zmkjcd.onaliyun.com</div>
            </div>
          </div>
        </div>
      </div>
      <!-- copyright -->
      <div class="copyright">
        <span>版权所有：四川中京安民科技有限公司</span>
        <span class="ml-50">备案号：<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2021007915号-2</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  setup() {},
});
</script>

<style lang="less" scoped>
.footer{
  background-color: #06031E;
  color: #999999;
  .mobile-outlink{
    display: none;
  }
  .describe{
    background-color: #F6F9FF;
    padding: 55px 0;
    .describe-list{
      display: flex;
      justify-content: space-between;
      .describe-item{
        width: 23%;
        font-size: 15px;
        color: rgba(20, 45, 112, .5);
        img{
          width: 40px;
          height: 40px;
          margin: 0 auto;
          display: block;
        }
        .des-title{
          font-size: 18px;
          color: #fff;
          font-weight: 600;
          text-align: center;
          margin-top: 18px;
          color: #142D70;
        }
      }
    }
  }
  .pt-40{
    padding-top: 40px;
  }
  .footer-info{
    color: #99A8C1;
    font-size: 13px;
    .footer-logo{
      width: 201px;
      height: 34px;
      margin-bottom: 20px;
    }
    .footer-text {
      margin-top: 30px;
      margin-left: -2px;
      font-size: 14px;
      display: flex;
      div {
        display: flex;
        align-items: center;
        .logo {
          margin-right: 5px;
        }
      }
      div +div {
        margin-left: 30px;
      }
    }
  }
  .footer-link{
    font-size: 10px;
    color: #9FADC5;
    line-height: 3;
    .title{
      font-size: 13px;
      color: #fff;
    }
    .link{
      color: #9FADC5;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
        color: #7C83E2;
      }
    }
  }
  .footer-code{
    text-align: center;
    font-size: 10px;
    color: #9FADC5;
    >div{
      margin-left: 100px;
    }
    img{
      width: 110px;
      height: 110px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .copyright{
    font-size: 12px;
    color: #778899;
    padding: 30px 0 20px;
    .ml-50{
      margin-left: 50px;
    }
  }
  a {
      color: #6e748d;
    }
}
.modal-img{
  width: 100%;
  margin: 0 auto;
  display: block;
}
</style>

<style lang="less" scoped>
.mobile{
  .footer{
    .footer-info{
      .footer-logo{
        width: 140px;
        height: 23px;
        margin-bottom: 20px;
      }
      .footer-text {
        margin-top: 20px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        div +div {
          margin-left: 0px;
        }
        .address {
          margin-bottom: 10px;
        }
        .phone {
          margin-bottom: 10px;
        }
      }
    }
    .describe{
      .describe-list{
        flex-wrap: wrap;
        padding: 0 20px;
        .describe-item{
          width: 48%;
          margin-bottom: 20px;
          font-size: 14px;
          text-align: center;
          .des-title{
            font-size: 16px;
          }
        }
      }
    }
    .footer-main{
      display: block;
      padding: 40px 20px;
    }
    .mobile-outlink{
      display: block;
    }
    .pc-outlink{
      display: none;
    }
    .footer-link{
      font-size: 13px;
    }
    .footer-code{
      margin-top: 30px;
      >div{
        margin: 0;
      }
    }
    .copyright{
      padding: 0 20px 20px 20px;
      font-size: 13px;
      .ml-50{
        display: block;
        margin: 0;
      }
    }
  }
}
</style>>