<template>
  <div class="header header-bg">
    <!-- 菜单 -->
    <div class="ui header-wrap">
      <img class="logo" src="@/assets/images/footer-logo.png" alt="" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import { message } from 'ant-design-vue';

export default defineComponent({
  setup() {}
});
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  transition: 0.5s;
  &.header-bg {
    background: url("../assets/images/menu-bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .menu-icon {
    display: none;
  }
  .header-wrap {
    align-items: center;
    padding: 15px 0 15px;
    width: 1200px;
    margin: 0 auto;
    .logo {
      width: 201px;
      height: 34px;
    }
    .menu {
      display: flex;
      color: #fff;
      font-size: 15px;
      .menu-item {
        cursor: pointer;
        position: relative;
        margin-left: 93px;
        &::after {
          content: "";
          display: block;
          width: 18px;
          height: 3px;
          background-color: transparent;
          position: absolute;
          bottom: -10px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          &::after {
            background-color: #fff;
          }
        }
        &.menu-active {
          font-weight: 600;
          &::after {
            background-color: #fff;
          }
        }
      }
    }
  }
}
.logo-wrap {
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  padding: 20px 0;
  /deep/.ant-input {
    background: #f7f7f7;
    border: 1px solid #ececec;
    width: 350px;
  }
  .header-logo {
    width: 224px;
  }
}
</style>

<style lang="less" scoped>
.mobile {
  .header {
    padding-left: 10px;
    .menu-icon {
      display: block;
      font-size: 25px;
      color: #5AA1FF;
      position: absolute;
      right: 10px;
    }
     .header-wrap {
       width: 100%;
       padding: 10px 0 12px;
       .logo{
        width: 140px;
        height: 23px;
       }
       .menu{
        display: block;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        background: rgba(4, 2, 21, .9);
        
        .menu-item{
          margin: 10px 0;
          padding: 0 20px;
          &::after{
            display: none;
          }
          &.menu-active{
            background-color: #1A4FBA;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}
</style>